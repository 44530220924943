/**/
.wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
}

.container {
    height: 50px;
    background-color: white;
    display: flex;
    align-items: center;
    /*border: 1px solid var(--border-color);*/
    border-radius: 4px;
    position: relative;
    /*border: 1px solid rgb(229, 232, 232);*/
    border: 1px solid var(--gray);
}

.focused {
    border: 1px solid var(--dark-blue);
    transition: border 500ms ease;
}

.prefix {
    width: 50px;
    min-width: 50px;
    display: flex;
    justify-content: center;
}

.suffix {
}

.hover {
    top: -10px !important;
    transition: top 0.3s ease, left 0.3s ease;
    left: 10px !important;
    background-color: white;
    padding: 2px 4px 4px 4px;
    font-size: 12px !important;
}

.error {
    position: absolute;
    font-size: 12px;
    color: var(--dark-gray);
}

.errorOverflow {
    overflow: visible;
    white-space: nowrap;
}

.red {
    color: var(--red);
}

/*.iput[type="password"] {*/
/*    font-variant: normal;*/
/*    text-transform: none;*/
/*    font-size: 25px;*/
/*    margin-top: -3px;*/
/*    background: transparent;*/
/*    letter-spacing: 1px;*/
/*}*/

.placeholder {
    position: absolute;
    pointer-events: none;
    color: var(--placeholder-gray);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 13px;
    left: 14px;
    top: 18px;
}

.input {
    border: 0;
    height: 46px;
    margin-left:2px;
    padding-left: 12px;
    width: calc(100% - 15px);
    box-shadow: none;
    caret-color: var(--dark-blue);
    font-size: 13px;
}

.input::placeholder {
    color: var(--gray);
}

.prefixedWidth {
    width: calc(100% - 70px) !important;
}

.input:focus {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.affirmed {
    border: 1px solid var(--darkest-blue);
}

.affirmed.focused {
    border: 1px solid var(--darkest-blue) !important;
}

.moveRight {
    left: 50px;
}

.moveLeft {
    margin-left: 0;
    padding-left: 0;
}

.errorBelow {
    color: var(--red);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 8px;
    padding-right: 10px;
    margin-left: 14px;
}

.infoBelow {
    color: var(--dark-gray);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 8px;
    padding-right: 10px;
    margin-left: 14px;
}

.affirmationBelow {
    color: var(--dark-blue) !important;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 8px;
    padding-right: 10px;
    margin-left: 14px;
}

.darkInputWrapper {
    background-color: var(--black);
}

.darkInputWrapper.focused {
    border: 1px solid var(--light-gray);
}

.darkPlaceholder {
    background-color: var(--black) !important;
    color: var(--light-gray);
}

.darkInput {
    background-color: var(--black);
    color: white;
}

.disabled {
    background: transparent;
}

.disabled .input {
    background: transparent;
    color: var(--placeholder-gray) !important;
}

.disabled .placeholder {
    background: var(--background-color);
}

.transparent {
    background: transparent !important;
    border: 1px solid transparent !important;
}

.input.transparent {
    color: var(--black) !important;
}

/* Action claass for clickable text above input (used for pre-filling) */
.action {
    color: var(--dark-blue);
    margin-left: auto;
    margin-bottom: 11px;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    outline: none;
    border: none;
    background: transparent;
    padding: 0;
}

.action:hover {
    cursor: pointer;
}

.action:focus {
    outline: none;
    border: none;
}

.actionFloating {
    position: absolute;
    top: -27px;
    right: 0;
}
