.wrapper {
    min-height: 100vh;
    width: 100vw;
    display: flex;
}

.staticSection {
    flex: 1;
}

.logoWrapper {
    margin-right: 350px;
    margin-top: 31px;
    margin-left: auto;
    width: 162px;
    margin-bottom: 32px;
}

.imageWrapper {
    max-width: 586px;
    margin-right: 50px;
    margin-left: auto;
}

.imageWrapper > img {
    max-width: 100%;
}

.loginSection {
    flex: 1;
    background: var(--black);
    padding-bottom: 130px;
}

.loginWrapper {
    margin-top: 37px;
    margin-left: 158px;
    width: 355px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.langPickerWrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 115px;
    align-self: flex-end;
}

.title {
    color: var(--dark-blue);
    font-weight: bold;
    font-size: 48px;
    text-align: center;
    margin-bottom: 11px;
}

.dot {
    color: var(--yellow);
}

.description {
    color: var(--dark-gray);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 34px;
    margin-left: -20px;
    margin-right: -20px;
}

.forgotPassword {
    color: var(--dark-gray);
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: center;
}

.forgotPassword:hover, .forgotPassword:active {
    color: var(--yellow-dark);
    text-decoration: none;
}

.posQuestion {
    color: var(--dark-gray);
    line-height: 22px;
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 95px;
}

.posRedirectWrapper {
    display: flex;
    justify-content: center;
}

.posLink {
    padding-bottom: 2px;
    color: var(--dark-gray);
    margin-left: 10px;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    border-bottom: 1px solid var(--placeholder-gray);
}

.posLink:hover {
    color: var(--dark-gray);
    text-decoration: none;
}

@media(max-width: 1049px) {
    .description {
        font-size: 12px;
        margin-bottom: 22px;
    }

    .title {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 6px;
    }

    .posQuestion {
        margin-top: 50px;
        font-size: 13px;
    }

    .posRedirectWrapper > * {
        font-size: 13px;
    }
}

@media(max-width: 374px) {
    .description {
        font-size: 11px;

    }
}
