.list {
    position: absolute;
    top: 40px;
    /*left: -25px;*/
    left: -45px;
    z-index: 1020;
    /*transform: translateZ(1020px);*/
    -webkit-transform: translate3d(0,0,0);
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(0, 173, 187, 0.1);
    box-shadow: 0px 2px 4px 0px rgba(0, 173, 187, 0.07);
    width: 123px;
}

.blue {
    top: 20px;
}

.extended {
    left: -32px;
}


.listItem {
    height: 29px;
    font-size: 13px;
    color: var(--black);
    padding-left: 7px;
    padding-right: 7px;
    margin-left: 6px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--langpicker-border-gray);
}

.extended > .listItem {
    padding-left: 0 !important;
}

.listItem:last-child {
    border: none !important;
}

.selected {
    font-weight: bold;
}

.listItem:hover {
    cursor: pointer;
    font-weight: bold;
}

.blue > .listItem {
    font-size: 11px;
}

.blue > .selected {
    font-weight: 400;
    color: var(--dark-blue);
}

.blue > .listItem:hover {
    font-weight: 400;
    color: var(--dark-blue);
}

.checkmark {
    width: 20px;
    margin-right: 5px;
}


@media(max-width: 559px) {
    .list {
        display: none !important;
    }
}
