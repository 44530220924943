.form {
    width: 355px;
    background: white;
    margin-bottom: 30px;
    border-radius: 1px;
}

.innerForm {
    padding: 20px 20px 24px 20px;
}

.input {
    width: 100%;
    margin-bottom: 20px;
}

.checkbox {
    padding-top: 4px;
    padding-left: 14px;
    padding-right: 14px;
}

.button {
    height: 65px !important;
    width: 100%;
    text-transform: uppercase;
}

.toggler {
    margin-right: 10px;
    width: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggler:hover {
    cursor: pointer;
}

.togglerText {
    font-size: 11px;
    color: var(--dark-gray);
}

.togglerIcon {
    height: 30px;
    width: 30px;
    
}

@media(max-width: 1049px) {
    .form {
        max-width: 355px;
        width: available;
        margin-bottom: 26px;
    }
}

@media(max-width: 374px) {
    .form {
        width: 100%;
        margin-bottom: 26px;
    }
}
