.smallFont {
    font-size: 10px;
}

.smallerFont {
    font-size: 12px;
}

.normalFont {
    font-size: 13px;
}

.largeFont {
    font-size: 15px;
}

.w500 {
    font-weight: 500;
}

.w400 {
    font-weight: 400;
}

.small {
    height: 28px;
}

.normal {
    height: 40px;
}

.large {
    height: 50px;
}

.huge {
    height: 60px;
}

.button {
    display: flex;
    transition: all 0.2s ease;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    letter-spacing: 0px;
    text-align: center;
}

.icon {
    margin-right: 10px;
}

.iconAfter {
    margin-left: 5px;
    pointer-events: none;
}

.white .icon g * {
    stroke: black;
    transition: all 0.2s ease;
}

.white:hover .icon g *,
.white:active .icon g *,
.white.active .icon g * {
    stroke: var(--dark-blue);
    transition: all 0.2s ease;
}

.hasIcon {
    padding-left: 18px;
    padding-right: 20px;
}

.white {
    background: white;
    border: 1px solid var(--final-border-color);
    color: var(--black);
}

.white:hover,
.white.active {
    cursor: pointer;
    border: 1px solid var(--darkest-blue);
    box-shadow: 0px 2px 4px 0px rgba(0, 173, 187, 0.15);
    background: var(--transparent-blue);
    color: var(--dark-blue);
}

.white:focus {
    outline: none;
}

.white:active {
    box-shadow: none;
    border: 1px solid var(--final-border-color);
}

.blue {
    background: var(--dark-blue);
    border: 0;
    color: white;
}

.blue:hover,
.blue.active {
    background: var(--darkest-blue);
    box-shadow: 0px 2px 4px 0px rgba(0, 173, 187, 0.15);
}

.blue:focus {
    outline: none;
}

.blue:active,
.blue:focus {
    background: var(--darkest-blue);
    box-shadow: none;
}

.gray {
    background: var(--light-gray);
    border: 1px solid var(--border-color);
    color: var(--dark-gray);
}

.gray:hover,
.gray.active {
    cursor: pointer;
    border: 1px solid var(--light-gray);
    box-shadow: 0px 2px 4px 0px rgba(127, 140, 141, 0.14);
    background: var(--light-gray);
    color: var(--dark-gray);
}

.gray:focus {
    outline: none;
}

.gray:active {
    box-shadow: none;
    border: 1px solid rgba(127, 140, 141, 0.2);
    background: var(--darkest-gray);
}

.yellow {
    background: var(--yellow);
    border: 0;
    font-weight: 500;
    box-shadow: 0px 2px 4px 0px rgba(0, 173, 187, 0.07);
    color: var(--black);
}

.yellow:hover,
.yellow.active {
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px rgba(0, 173, 187, 0.07);
    background: var(--yellow-dark);
    color: var(--black);
}

.yellow:focus {
    outline: none;
}

.yellow:active {
    box-shadow: none;
    border: 1px solid rgb(211, 177, 0);
    background: var(--yellow-dark);
}

.transparent {
    border: 0;
    padding: 3px 10px;
    background: transparent !important;
    border: transparent !important;
    color: var(--cancel-button-gray);
}

.transparent:active,
.transparent:focus {
    outline: none;
}

.transparent:hover {
    cursor: pointer;
}

.black {
    background: var(--black);
    border: 0;
    color: white;
    height: 57px;
}

.black g * {
    stroke: white;
}

.black:active,
.black:focus {
    outline: none;
}

.black:hover {
    cursor: pointer;
}

.disabled,
.disabled:hover,
.disabled:focus,
.disabled:active {
    background: var(--almost-white);
    border: 1px solid var(--gray);
    outline: none;
    box-shadow: none;
    color: var(--gray);
    pointer-events: none;
}

.disabled:hover {
    cursor: pointer !important;
}

.disabled.blue {
    color: var(--cancel-button-gray) !important;
    border: 1px solid var(--light-gray) !important;
    background-color: transparent;
}

.disabled.transparent {
    color: var(--gray);
}

.disabled.black {
    background: transparent !important;
    border: transparent !important;
    color: unset;
}

.noRound {
    border-radius: 0 !important;
}

.loading:hover {
    cursor: default !important;
    /*position: absolute;*/
    /*right: -5px;*/
}

.loading.white:focus,
.loading.white:active {
    border: 1px solid var(--darkest-blue) !important;
    box-shadow: 0px 2px 4px 0px rgba(0, 173, 187, 0.15);
}

.picker__button {
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 18px 16px;
    background-color: var(--white);
}

.picker__button:hover {
    cursor: pointer;
    box-sizing: border-box;
    background: rgb(240, 250, 251);
    border-radius: 4px;
    padding: 10px 8px;
    margin: 8px;
    border: 1px solid rgb(0, 143, 161);
}

.button__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    border-radius: 4px;
    height: 42px;
    width: 42px;
    background: linear-gradient(180deg, #fff, #d0e9ee);
    box-shadow: 0 1px 3px 0 rgb(97 111 116 / 10%);
    padding: 1px;
}

.button__icon__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgb(238, 247, 248);
    border-radius: 3px;
    box-shadow: 0px 1px 3px 0px rgba(97, 111, 116, 0.1);
}

.button__text {
    margin-right: auto;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
}

.text__title {
    display: inline-block;
    text-align: left;
    width: 100%;
    color: rgb(0, 20, 27);
    font-weight: bold;
    margin-right: auto;
}

.text__subtitle {
    display: inline-block;
    width: 100%;
    color: rgb(123, 136, 137);
    font-size: 10px;
    font-weight: bold;
    text-align: left;
}
